<template>
  <section class="mt-4 mb-3">
    <PageMetadata />
    <EnterEmailRewards v-if="enterEmail" v-model:showModal="enterEmail" :submitFn="getCarts" />
    <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
      <span v-html="flashAlert.msg"></span>
    </flash-alert>
    <loading-overlay :active="isLoading" :is-full-page="fullPage" :loader="loader"/>
    <div v-if="cartsCount > 0" id="content" class="col-sm-12">
      <div class="align-items-center justify-content-between mb-4">
        <h1 v-if="isSalesRep || isESales" class="px-0 text-color-orange">
          {{ loggedinUserDetails.fname }} {{ getMetaDetails.page_h1 }}
        </h1>
        <h1 v-else class="px-0">{{ getMetaDetails.page_h1 }}</h1>
        <component :is="'style'" v-if="getMetaDetails.additional_style != null && getMetaDetails.additional_style != ''">
          {{ getMetaDetails.additional_style }}
        </component>
        <div class="content_below_heading" v-if="!!getMetaDetails.description" v-html="getMetaDetails.description"></div>
      </div>

      <div class="buttons clearfix" v-if="cartsCount > 1">
        <div class="text-center text-md-end">
          <a v-if="this.getCurrentShoppingLists && this.getCurrentHolHolidays" :href="`/checkout?type=${this.getCurrentCartTypes}&list=${this.getCurrentShoppingLists}&hol_holiday=${this.getCurrentHolHolidays}`" class="btn btn-primary">Checkout All</a>
          <a v-else-if="this.getCurrentShoppingLists" :href="`/checkout?type=${this.getCurrentCartTypes}&list=${this.getCurrentShoppingLists}`" class="btn btn-primary">Checkout All</a>
          <a v-else-if="this.getCurrentHolHolidays" :href="`/checkout?type=${this.getCurrentCartTypes}&hol_holiday=${this.getCurrentHolHolidays}`" class="btn btn-primary">Checkout All</a>
          <a v-else :href="`/checkout?type=${this.getCurrentCartTypes}`" class="btn btn-primary">Checkout All</a>
        </div>
      </div>
      <br />
      <template v-for="(cart, index) in carts" :key="index">
        <div class="ecommerce">
          <h2 v-if="cart.header.program_type.toUpperCase() == 'SBL'" class="text-center text-md-start" >{{ cart.header.shopping_list_name }}</h2>
          <h2 v-else-if="cart.header.program_type.toUpperCase() == 'DBL2'" class="text-center text-md-start" >{{ cart.header.hol_holiday_name }}</h2>
          <h2 v-else class="text-center text-md-start" >{{ cart.header.program }}</h2>
        </div>
        <div v-if="cart.header.delivery_date" class="text-center text-md-start" >
          <strong class="d-inline">Delivery Date: </strong>{{ cart.header.delivery_date }}
        </div>
        <div class="text-center text-md-start mb-3" >
          <span>
            <strong class="d-inline">Cutoff: </strong>
            <template v-for="(cutoff, index) in cart.header.cutoffs" :key="index">
              <span :class="{ 'pl-78': index >= 1 }">
                {{ cutoff.cutoff }} {{ cutoff.cutoff_timezone }}
                <template v-if="cutoff.label">
                  ({{ cutoff.label }})
                </template>
                <br>
              </span>
            </template>
          </span>
        </div>

        <div class="table-responsive-old">
          <!-- new cart designs-->
          <div class="product-wrapper" id="view-list">
             <div v-if="isLoggedIn" class="card product-card cart-page border-0">
               <div class="card-header bg-grey d-none d-lg-block text-white">
                 <div class="row mx-0">
                   <div class="col-auto product-img-wrap">Image</div>
                   <div class="col product-name pl-0">Product Name</div>
                   <div class="col col-lg-auto quantity">Quantity</div>
                   <div class="col col-lg-auto price">Price/Unit</div>
                   <div class="col col-lg-auto lot-price">Total</div>
                   <div class="col-auto action">Remove</div>
                 </div>
               </div>
               <div v-for="(product, counter) in cart.products" :key="counter" class="card-body position-relative">
                 <div class="row no-gutters">
                   <div class="col-auto product-img-wrap position-relative no-overflow">
                     <span v-if="product.program_type == 'MMT' || product.program_type == 'WSP' || product.program_type == 'SBL'">
                        <img
                          v-if="product.image"
                          :src="`${imagePath}` + product.image"
                          class="img-thumbnail"
                          :title="product.name"
                          :alt="product.name"
                        />
                        <img
                          v-else
                          :src="
                            `${imagePath}` +
                            getDefaultImage(product.product_type_id)
                          "
                          class="img-thumbnail"
                          :title="product.name"
                          :alt="product.name"
                        />
                      </span>
                      <span v-else-if="product.program_type == 'DBL' || product.program_type == 'DBL2'">
                        <img
                          v-if="product.product_image"
                          :src="product.product_image"
                          class="img-thumbnail"
                          :title="product.product_name"
                          :alt="product.product_name"
                        />
                        <img
                          v-else
                          :src="
                            `${imagePath}` +
                            getDefaultImage(product.product_type_id)
                          "
                          class="img-thumbnail"
                          :title="product.product_name"
                          :alt="product.product_name"
                        />
                      </span>
                      <span v-else>
                        <a :href="`/${product.seo_url}`">
                          <img
                            v-if="product.image"
                            :src="`${imagePath}` + product.image"
                            class="img-thumbnail"
                            :title="product.name"
                            :alt="product.name"
                          />
                          <img
                            v-else
                            :src="
                              `${imagePath}` +
                              getDefaultImage(product.product_type_id)
                            "
                            class="img-thumbnail"
                            :title="product.name"
                            :alt="product.name"
                          />
                        </a>
                      </span>
                    </div>
                    <div class="col product-name  pl-0">
                      <div>
                        <span v-if="product.program_type == 'MMT' || product.program_type == 'WSP' || product.program_type == 'SBL'">
                          {{ product.name }}
                        </span>
                        <span v-else-if="product.program_type == 'DBL' || product.program_type == 'DBL2'">
                          {{ product.product_name }}
                        </span>
                        <span v-else>
                          <a :href="`/${product.seo_url}`">{{ product.name }}</a>
                        </span>
                        <br>
                        <template v-if="product.program_type != 'MMT'">
                          <span v-if="product.farm_name"><i>{{ product.farm_name }}</i></span>
                        </template>
                        <template v-else>
                          <span v-if="product.origin_name"><i>{{ product.origin_name }}</i></span>
                        </template>
                        <br />
                      </div>
                    </div>
                   <div class="w-100 d-block d-lg-none"></div>
                   <div class="col col-lg-auto quantity">
                     <div>
                       <label class="d-block d-lg-none text-center text-lg-start">Quantity</label>
                       <update-cart :product="product"></update-cart>
                     </div>
                   </div>
                   <div class="col col-lg-auto price fw-normal">
                     <div>
                       <label class="d-block d-lg-none text-center">Price/Unit</label>
                       <div
                          class="ml-10 text-center text-lg-end"
                          v-if="
                            product.program_type == 'LAL' ||
                            product.program_type == 'PRB' || 
                            product.program_type == 'WSP' || 
                            product.program_type == 'SBL'
                          "
                          >
                          <span
                            v-if="
                              product.sold_by_singular != product.priced_by_singular
                            "
                            >${{ $filters.numberFormat(product.stem_price) }} /
                            {{ product.priced_by_singular }}</span
                          >
                          <span
                            v-if="
                              product.sold_by_singular == product.priced_by_singular
                            "
                            >${{ $filters.numberFormat(product.stem_price) }} /
                            {{ product.priced_by_singular }}</span
                          >
                        </div>
                        <div class="ml-10 text-center text-lg-end" v-else-if="product.program_type == 'HGS'">
                          <span
                            >${{ $filters.numberFormat(product.stem_price) }} /
                            {{ product.sold_by_singular }}</span
                          >
                        </div>
                        <div class="ml-10 text-center text-lg-end" v-else-if="product.program_type == 'MDB' || product.program_type == 'FDB' || product.program_type == 'DBL' || product.program_type == 'DBL2'">
                          <span
                            >${{ $filters.numberFormat(product.stem_price) }} /
                            {{ product.box_unit_singular }}</span
                          >
                        </div>
                        <div class="ml-10 text-center text-lg-end" v-else>
                          ${{ $filters.numberFormat(product.stem_price) }} / Stem
                        </div>
                     </div>
                   </div>
                   <div class="col col-lg-auto lot-price">
                     <div class="text-center text-lg-end">
                       <label class="d-block d-lg-none text-center">Total</label>
                       <span >${{ product.total }}</span>
                     </div>
                   </div>
 
                   <div class="action text-right" :class="[isLoggedIn ? 'col-auto' : 'col-12']">
                     <div>
                       <label class="d-none d-md-block d-md-none">&nbsp;</label>
                       <delete-product
                    :itemId="product.cartItemId"
                    :product="product"
                  ></delete-product>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
          </div>
          <!-- new cart design ends-->
        </div>
        <div class="row justify-content-end mt-3">
          <div class="col-sm-4 col-sm-offset-8">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td class="text-right"><b>Sub-Total:</b></td>
                  <td class="text-right">${{ cart.subTotal }}</td>
                </tr>
                <tr v-if="cart.taxes">
                  <td class="text-right"><b>Taxes:</b></td>
                  <td class="text-right">${{ cart.taxes }}</td>
                </tr>
                <tr v-if="cart.shipping_selected">
                  <td class="text-right">
                    <b>{{ cart.shipping_selected.shipping_method_name }}</b>
                  </td>
                  <td class="text-right">
                    {{ shippingCost(cart.shipping_selected) }}
                  </td>
                </tr>
                <tr>
                  <td class="text-right"><b>Total:</b></td>
                  <td class="text-right">${{ cart.total }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="buttons clearfix">
          <div class="text-center text-md-end">
            <span v-if="isEnrolledLoyalty && cart.loyalty != ''" class="d-block d-md-inline pe-0 pe-md-4 py-4 py-md-0 peach-color">{{ cart.loyalty }}</span>
            <a v-if="cart.header.program_type.toLowerCase()=='sbl'" :href="`/checkout/?type=${cart.header.program_type}&list=${cart.header.shopping_list_id}`" class="btn btn-primary">Checkout</a>
            <a v-else-if="cart.header.program_type.toLowerCase()=='dbl2'" :href="`/checkout/?type=${cart.header.program_type}&hol_holiday=${cart.header.hol_holiday_id}`" class="btn btn-primary">Checkout</a>
            <a v-else :href="`/checkout/?type=${cart.header.program_type}`" class="btn btn-primary">Checkout</a>
          </div>
        </div>
        <div class="buttons clearfix pt-4">
          <div class="text-center text-md-end">
            <a v-if="cart.header.program_type == 'dbl'" :href="'/dutch-direct-boxlots'" class="">Continue Shopping</a>
            <a v-else-if="cart.header.program_type == 'hgs'" :href="'/hardgoods'" class="">Continue Shopping</a>
            <a v-else-if="cart.header.program_type == 'mmt'" :href="'/luxe-blooms-grocery'" class="">Continue Shopping</a>
            <a v-else-if="cart.header.program_type == 'sbl'" :href="`/special-offers/${cart.header.shopping_list_slug}`" class="">Continue Shopping</a>
            <a v-else-if="cart.header.program_type == 'dbl2'" :href="`/special-offers/${cart.header.hol_holiday_slug}`" class="">Continue Shopping</a>
            <a v-else :href="'/shop'" class="">Continue Shopping</a>
          </div>
        </div>
        <hr />
        <!--MIAMI DIRECT BOXLOTS-->
      </template>
      <div v-if="canEnrollLoyalty && canChangeLoyaltyStatus" class="text-center text-md-end">
        <div class="row justify-content-center justify-content-md-end">
          <div class="col-auto d-flex align-items-center pe-1">
            <img src="/backend/files/pages/petals-rewards-logo.png" alt="Petals Rewards" class="img-fluid" style="width: 73px;">
          </div>
          <div class="col-auto d-flex align-items-center ps-1">
            <span class="peach-color fw-bold text-end no-letter-spacing d-block">Start saving in under 30 seconds<br>by signing up for Petals Rewards.</span>
          </div>
        </div>
        <a @click="displayEnterEmail" class="sign-up-btn btn checkout-btn no-letter-spacing text-uppercase">Sign Me Up!</a>
      </div>
      <div id="page_content_below" class="mt-10 content-wrapper" v-if="!!getMetaDetails.description_below_dynamic" v-html="getMetaDetails.description_below_dynamic"></div>
      <template v-if="cartsCount > 1">
        <br /><br />
        <div class="buttons clearfix">
          <div class="text-center text-md-end">
            <a v-if="this.getCurrentShoppingLists && this.getCurrentHolHolidays" :href="`/checkout?type=${this.getCurrentCartTypes}&list=${this.getCurrentShoppingLists}&hol_holiday=${this.getCurrentHolHolidays}`" class="btn btn-primary">Checkout All</a>
            <a v-else-if="this.getCurrentShoppingLists" :href="`/checkout?type=${this.getCurrentCartTypes}&list=${this.getCurrentShoppingLists}`" class="btn btn-primary">Checkout All</a>
            <a v-else-if="this.getCurrentHolHolidays" :href="`/checkout?type=${this.getCurrentCartTypes}&hol_holiday=${this.getCurrentHolHolidays}`" class="btn btn-primary">Checkout All</a>
            <a v-else :href="`/checkout?type=${this.getCurrentCartTypes}`" class="btn btn-primary">Checkout All</a>
          </div>
        </div>
      </template>
    </div>
    <div v-else>
      <div class="d-flex align-items-center justify-content-between mb-4">
        <h1 class="px-0">Shopping Cart</h1>
      </div>
      <div id="empty_cart">Your shopping cart is empty!</div>
      <br />
      <div class="buttons clearfix">
        <div class="pull-left">
          <a href="/shop" class="btn btn-primary">Continue</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { FlashAlert, DeleteProduct, UpdateCart, PageMetadata, EnterEmailRewards } from "@/components";
import { mapGetters } from "vuex";
import { misc } from "@/mixins";
import axios from "axios";

export default {
  name: "CartsPage",
  mixins: [misc],
  components: { DeleteProduct, FlashAlert, UpdateCart, PageMetadata, EnterEmailRewards },
  data() {
    return {
      carts: [],
      cartsCount: 1,
      isLoading: false,
      fullPage: true,
      loader: "bars",
      allCartsCode: "",
      enterEmail: false,
    };
  },
  created() {
    this.getCarts();
    this.isLoading = false;
  },
  mounted() {
    localStorage.setItem("currentPage", "shopping-cart");
    this.fetchMetaDetails();
    this.emitter.on("deletedProduct", (payload) => {
      this.productDeleted(payload);
    });

    this.emitter.on("cartUpdated", (payload) => {
      this.cartUpdated(payload);
    });

    this.emitter.on("showLoader", () => {
      this.showLoader();
    });

    this.emitter.on("cartExpiredReload", () => {
      this.cartExpired();
    });
  },
  computed: {
    ...mapGetters([
      "loggedinUserDetails", 
      "isSalesRep", 
      "isESales", 
      "isEnrolledLoyalty", 
      "canEnrollLoyalty", 
      "canChangeLoyaltyStatus"
    ]),

    name() {
      return localStorage.getItem("name");
    },
  },
  methods: {
    displayEnterEmail() {
      if (this.canEnrollLoyalty && this.canChangeLoyaltyStatus) {
        this.enterEmail = true;
      }
    },
    cartExpired() {
      this.getCarts();
      this.isLoading = false;
    },
    getCarts() {
      this.isLoading = true;
      this.carts = [];
      try {
        axios
          .post(process.env.VUE_APP_API_BASE + "auth/getCarts", 
          {
            is_sales_rep: this.isSalesRep,
            is_e_sales: this.isESales,
          },
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.carts = response.data.carts;
            this.cartsCount = this.carts.length;
            if (this.carts.length == undefined)
              this.cartsCount = Object.getOwnPropertyNames(this.carts).length;
            //this.cartsCount = this.carts.length
            let allPrograms = Array();
            if (this.cartsCount > 0) {
              Object.values(this.carts).forEach((val) => {
                allPrograms.push(val.header.program_type);
              });
            }

            if (allPrograms.length > 0) {
              this.allCartsCode = allPrograms.join();
            }
            this.isLoading = false;
          });
      } catch {
        console.log("Something went wrong");
      }
    },
    productDeleted() {
      this.getCarts();
      // this.showFlashAlert(response.msg, response.level);
      this.isLoading = false;
    },
    cartUpdated(response) {
      this.getCarts();
      this.showFlashAlert(response.msg, response.level);
      this.isLoading = false;
    },
    showLoader() {
      this.isLoading = true;
    },
    shippingCost(selected_shipping) {
      if (selected_shipping.cost > 0) {
        return "$" + parseFloat(selected_shipping.cost).toFixed(2);
      } else if (selected_shipping.cost == 0 && selected_shipping.is_tbd == 1)
        return "TBD";
      else if (selected_shipping.cost == 0 && selected_shipping.is_tbd == 0)
        return "FREE";
    },
  },
};
</script>
<!-- <style>
  /* .product-card .quantity {
    width: 280px !important;
    max-width: 280px !important;
    flex-basis: 280px !important;
  } */
  @media screen and (min-width:768px) and (max-width:991px) {
    .product-card .quantity {
      width: 270px !important;
      max-width: 270px !important;
      flex-basis: 270px !important;
    }
  }
</style> -->
<style scoped>
.vld-overlay .vld-background {
  background: #000 !important;
  opacity: 0.5;
}

td.text-right {
  text-align: right;
}

.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.ml-10 {
  text-transform: lowercase;
}

.text-color-orange {
  color: #f48062;
}
.cart-page.product-card .product-img-wrap{
  --col-size: 60px;
  flex-basis: var(--col-size);
    max-width: var(--col-size);
    text-align:center;
}
.cart-page.product-card .price{
  --col-size: 195px;
  flex-basis: var(--col-size);
  max-width: var(--col-size);
  justify-content: flex-end;
}
.cart-page.product-card .lot-price{
  --col-size: 102px;
  flex-basis: var(--col-size);
  max-width: var(--col-size);
  justify-content: flex-end;
}
.cart-page.product-card .action{
  --col-size: 127px;
    flex-basis: var(--col-size);
    max-width: var(--col-size);
    justify-content: center;
}
.cart-page.product-card .quantity{
  --col-size:270px;
  flex-basis:var(--col-size);
  max-width:var(--col-size);
}
.cart-page {
  font-size: 11px;
}
.cart-page.product-card .card-body .row>div{margin-bottom:0}
.cart-page.product-card .quantity{
  --col-size:280px;
  flex-basis:var(--col-size);
  max-width:var(--col-size);
}
.w-90 {
  max-width: 560px !important;
  width: 100% !important;
  border: 1px solid #ccc;
}
.w-90 th {
  color: #000;
  background-color: #ccc;
}
.text-orange {
  color: #f48062 !important;
}
.pl-78 {
  padding-left: 78px;
}
.checkout-btn {
  background: #f48062;
  border: 2px solid #f37f62;
  color: #fff;
  margin: 10px 0;
}
.checkout-btn:hover {
  background: transparent;
  color: #f48062;
}
.sign-up-btn{
  cursor: pointer;
}
@media screen and (min-width:768px) and (max-width:991px) {
  .cart-page.product-card .price, .cart-page.product-card .lot-price{
  --col-size: 174px;
  flex-basis: var(--col-size);
  max-width: var(--col-size);
  justify-content: flex-end;
}

  .cart-page.product-card .action{
    --col-size: 70px;
      flex-basis: var(--col-size);
      max-width: var(--col-size);
      justify-content: center;
  }
  .cart-page.product-card .quantity{
    --col-size:270px;
    flex-basis:var(--col-size);
    max-width:var(--col-size);
    display: flex;
    justify-content: center;
  }
}
@media(max-width:767px){
  .cart-page.product-card .quantity{
    --quantity-col:106px;
    flex: 1 0 var(--quantity-col);
    max-width: var(--quantity-col);
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
  }
  :deep(.cart-page.product-card .quantity .tooltip-box){
    order:2;
  }
  :deep(.cart-page.product-card .quantity .tooltip-box .btn){
    margin-left: 0 !important;
    width: 100%;
  }
  .cart-page.product-card .action {
    --action-col:58px;
    flex: 1 0 var(--action-col);
    max-width: var(--action-col);
    flex-wrap: wrap;
  }
  .cart-page.product-card .price,.cart-page.product-card .lot-price {
    flex: 1 0 calc((100% - 58px - 106px)/2);
    max-width: calc((100% - 58px - 106px)/2);
    display: flex;
    justify-content: center;
  }
}
</style>
